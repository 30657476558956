import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface EmailTask {
    email: string;
    count: string;
    vendorId: string
}
interface SelectedVendor {
    [index: number]: any;

}
interface EmailTaskState {
    emailTasks: EmailTask[];
    selectedVendors: SelectedVendor
}

const initialState: EmailTaskState = {
    emailTasks: [],
    selectedVendors: [],
};

const emailTaskSlice = createSlice({
    name: 'emailTasks',
    initialState,
    reducers: {
        clearEmailTasks(state) {
            state.emailTasks = [];
        },
        addEmailTask: (state, action) => {
            state.emailTasks.push({
                email: action.payload.email,
                count: action.payload.count,
                vendorId: action.payload.vendorId
            });
        },
        updateEmailTask: (state, action) => {
            const { index, task } = action.payload;
            state.emailTasks[index] = { ...task };
        },
        
        deleteEmailTask: (state, action) => {
            const index = action.payload;
            state.emailTasks.splice(index, 1); 
        
            const updatedVendors = Object.keys(state.selectedVendors)
                .filter((key) => parseInt(key) !== index)
                .reduce((acc, key: any) => {
                    const newIndex = parseInt(key) > index ? parseInt(key) - 1 : parseInt(key);
                    acc[newIndex] = state.selectedVendors[key];
                    return acc;
                }, {} as SelectedVendor);
        
            state.selectedVendors = updatedVendors; 
        },
        

        updateSelectedVendor(state, action: PayloadAction<{ index: number, vendor: any }>) {
            const { index, vendor } = action.payload
            state.selectedVendors[index] = vendor;
        },
        clearSelectedVendors(state) {
            state.selectedVendors = {};
        }
    },
});

export const { addEmailTask, clearEmailTasks, updateEmailTask, deleteEmailTask, updateSelectedVendor, clearSelectedVendors } = emailTaskSlice.actions;
export default emailTaskSlice.reducer;
