import { Button, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import Error from '../../components/error/error';
import { STATUS } from '../../utils/status';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store/appStore';
import { useStyles } from '../../assets/styles/listTableStyle';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CompletedTaskTableRow from './CompletedTaskTableRow';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { getProjectWiseAnalyticsAction } from '../../redux/slices/dashboard/dashboard';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  paginate: (pageNumber: number) => void;
  itemsPerPage: number;
  data: any;
  dataStatus: string;
}

const ActiveProjectsList: React.FC<{ itemsPerPage: number, data: any, dataStatus: string, currentPage: number, setCurrentPage: React.Dispatch<React.SetStateAction<number>> }> = ({ data, dataStatus, currentPage, setCurrentPage, itemsPerPage }) => {
  
  const headers = ["PROJECT NAME", "TOTAL TASK", "TOTAL TASK ALLOCATION", "NOT STARTED TASK", "ACTIVE TASK", "TASK COMPLETED", "AVG TASK PER DAY"]
  const totalPages = Math.ceil((data.count || 0) / itemsPerPage);
  const dispatch = useDispatch<AppDispatch>();
  const classes = useStyles();
  const { id } = useParams()

  useEffect(() => {
    dispatch(getProjectWiseAnalyticsAction({ page: currentPage, pageSize: itemsPerPage as number, association_type: "COMPANY" }));
  }, [dispatch, id, currentPage, itemsPerPage])

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: '#F7F9FA', '&:first-child td, &:first-child th': { borderBottom: 0 } }}>
            <TableRow>
              {headers.map((header) => (
                <TableCell align="left"
                  //  onClick={() => handleSort(header)}
                  sx={{ fontSize: '10px', fontWeight: 600, lineHeight: '14px' }}
                  key={header}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>

            {dataStatus === STATUS.PENDING ?
              <TableRow>
                <TableCell style={{textAlign:'center'}} colSpan={8}>
                  <CircularProgress size={20} />
                </TableCell>
              </TableRow>
              : dataStatus === STATUS.SUCESS && data.results && data.results.length > 0 ? (
                <>

                  {data.results.map((row: any) => (
                    <CompletedTaskTableRow key={row.id} row={row} />
                  ))}

                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={8} className={classes.noUsersMessage}>
                    <Error message={'There is no data added as of yet.'} />
                  </TableCell>
                </TableRow>
              )}
          </TableBody>
        </Table>

      </TableContainer>
      <ActiveProjectPagination
        currentPage={currentPage}
        totalPages={totalPages}
        paginate={paginate}
        itemsPerPage={itemsPerPage}
        data={data}
        dataStatus={dataStatus}
      />
    </>

  )
}

export const ActiveProjectPagination: React.FC<PaginationProps> = ({ currentPage, totalPages, paginate, itemsPerPage, data, dataStatus }) => {
  return (
    <>
      <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ color: '#10383D', fontFamily: 'Montserrat', fontSize: '14px', fontWeight: '400', lineHeight: '24px' }}>
            {dataStatus === STATUS.SUCESS ? (currentPage - 1) * itemsPerPage + 1 : '-'} to{' '}
            {dataStatus === STATUS.SUCESS
              ? Math.min(currentPage * itemsPerPage, data.count || 0)
              : '-'}{' '}
            of {dataStatus === STATUS.SUCESS ? (data.count || 0) : '-'}
          </Typography>
        </div>
        <Button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          sx={{ '&:hover': { backgroundColor: 'transparent' } }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ChevronLeftIcon
              sx={{
                color: 'black',
                backgroundColor: currentPage === 1 ? '#F3F5F7' : '#E8EBEE',
                width: '32px',
                height: '32px',
              }}
            />
          </div>
        </Button>
        <Button
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage == totalPages}
          sx={{ '&:hover': { backgroundColor: 'transparent' } }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ChevronRightIcon
              sx={{
                color: 'black',
                backgroundColor: !data.results || data.results.length < itemsPerPage ? '#F3F5F7' : '#E8EBEE',
                width: '32px',
                height: '32px',
              }}
            />
          </div>
        </Button>
      </div>
    </>
  )
}


export default ActiveProjectsList