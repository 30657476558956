import React, { useEffect, useMemo, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { STATUS } from '../../utils/status';
import { getProject, updateProject } from '../../redux/slices/projectSlice/addProjectSlice';
import { CircularProgress, Icon, IconButton, SelectChangeEvent, Tooltip } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { AddButton } from '../button/button';
import AddIcon from '@mui/icons-material/Add';
import DisableModal from './disableModal';
import SnackbarNotification from '../snackbar/snackbarNotification';
import { useStyles } from '../../assets/styles/listTableStyle';
import { SearchBar } from '../inputField/textfield';
import PaginationEntries from '../pagination/paginationEntries';
import TableRowItem from './projectTableRow';
import TableHeader from '../table/tableHeader';
import ProjectPagination from './projectPagination';
import Error from '../error/error';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { getTaskData, setGetTaskDataStatus } from '../../redux/slices/taskData';
import debounce from 'lodash.debounce';
import { getUser } from '../../redux/slices/userSlice/addUserSlice';

const AllTable = () => {
  const dispatch = useDispatch<AppDispatch>();
  const classes = useStyles();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortColumn, setSortColumn] = useState('created_at');
  const [sortOrder, setSortOrder] = useState('desc');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [projectIdToDelete, setProjectIdToDelete] = useState('');
  const [showDisableModal, setShowDisableModal] = useState(false);
  const [projectToDisable, setProjectToDisable] = useState<any>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('success');
  const [clickedProjectName, setClickedProjectName] = useState<string | null>(null);
  
  const {getTaskDataStatus, taskData } = useSelector((state: RootState) => state.taskData);
  const projectTableAccess = useSelector((state: RootState) => state.company?.company?.data?.modules?.projects?.table?.access);
  const searchAccess = useSelector((state: RootState) => state.company?.company?.data?.modules?.projects?.table?.search?.access);
  const addAccess = useSelector((state: RootState) => state.company?.company?.data?.actions?.create);
  const access = useSelector((state: RootState) => state.company.company.data.company_initially_onboarded_as)
  const tableFields = useSelector((state: RootState) => {
    const tableFields = state.company?.company?.data?.modules?.projects?.table?.table_fields;
    const filteredFields = tableFields ? tableFields.filter(field => field !== 'actions') : [];
    filteredFields.push('association');
    return filteredFields;
  });
  const association_type = useSelector((state: RootState) => state.projects.association_type);

  const projects = useSelector((state: RootState) => state.projects.projects);
  const getStatus = useSelector((state: RootState) => state.projects.getStatus);
  

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleSearchChange, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleDeleteClick = (projectId: string) => {
    setProjectIdToDelete(projectId);
    setShowDeleteModal(true);
  };

  const handleDisableCancel = () => {
    setShowDisableModal(false);
    setProjectToDisable(null);
  };

  const handleDisableConfirm = async () => {      
    setShowDisableModal(false);
    if (projectToDisable) {
      const updatedStatus = !projectToDisable.is_disabled;
      try {
        await dispatch(updateProject({ id: projectToDisable.id, is_disabled: updatedStatus }));
        dispatch(
          getProject({ search: searchTerm, page: currentPage, paginate: true, pageSize: itemsPerPage })
        );
      } catch (error) {
        console.error('Failed to update project status', error);
      }
    }
  };

  useEffect(() => {
    dispatch(getProject({ search: searchTerm, page: currentPage, paginate: true, pageSize: itemsPerPage}))
  }, [dispatch, searchTerm, currentPage, sortColumn, sortOrder, itemsPerPage]);

  const handleAddClick = () => {
    navigate('/addproject');
  };

  const handleClick = (row: any) => {
    setClickedProjectName(row.name);
    navigate(`/project/${row.project_id}`, { state: { projectDetails: row, id: row.id, projectName: row.name } });
    // dispatch(getProject({ id: row.id }))
  };

  const handleToggleDisable = (row: any) => {
    setProjectToDisable(row);
    setShowDisableModal(true);
  };

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleChangeRowsPerPage = (value:number) => {
    dispatch(getProject({ search: searchTerm, page: 1, pageSize: value as number }));
    setItemsPerPage(value);
    setCurrentPage(1);
  };
  const handleOnChange = (value:number) => {
    setItemsPerPage(value)
  }


  const handleStatusChange = async (row: any, newStatus: string) => {
    try {
      await dispatch(updateProject({ id: row.id, status: newStatus }));
      dispatch(
        getProject({})
      );
    } catch (error) {
      console.error('Failed to update project status', error);
    }
  };


  useEffect(() => {
    if (getTaskDataStatus === STATUS.SUCESS) {
      setSnackbarMessage('File is Available for Download')
      setSnackbarSeverity('success')
      setSnackbarOpen(true)
      if (typeof taskData.data == "string") {
        window.open(taskData.data, '_blank')
      }
    }

    if (getTaskDataStatus == STATUS.FAILED) {
      setSnackbarMessage('Couldn\'t Download File. Please Try Again.')
      setSnackbarSeverity('error')
      setSnackbarOpen(true)
    }

    return () => {
      if (getTaskDataStatus == STATUS.SUCESS) {
        dispatch(setGetTaskDataStatus(STATUS.NOT_STARTED))
      }

    }
  }, [getTaskDataStatus])


  const totalPages = Math.ceil((projects.count || 0) / itemsPerPage);
  const statuses = ['DRAFT', 'PUBLISHED', 'COMPLETED', 'ACTIVE', 'PAUSED'];

  const capitalizeAll = (str: string) => {
    return str.replace(/_/g, ' ')
      .split(' ')
      .map(word => word.toUpperCase())
      .join(' ');
  };

  const handleDownloadBtn = () => {
    const res: any = dispatch(getTaskData({association_type: association_type}));
  }

  return (
    <>
      {projectTableAccess && (<>
        <div className={classes.container}>
          {searchAccess && (<SearchBar onSearchChange={debouncedResults}/>)}
          <div className={classes.actionsContainer}>
            <PaginationEntries itemsPerPage={itemsPerPage} onChangeRowsPerPage={handleChangeRowsPerPage} handleOnChange={handleOnChange} />
              {access === 'COMPANY' &&
              <Tooltip title={"Download all projects"}>
                <IconButton
                  onClick={handleDownloadBtn}
                  disabled={getTaskDataStatus === STATUS.PENDING}
                  style={{
                    height: '40px',
                    borderRadius: '10px',
                    padding: '8px 6px 8px 6px',
                    marginTop: '10px',
                    marginRight: '20px',
                    backgroundColor: getTaskDataStatus === STATUS.PENDING ? '#dfdfdf' : '#3D85C6',
                  }}
                >
                  {getTaskDataStatus === STATUS.PENDING ? (
                    <CircularProgress size={17} />
                  ) : (
                    <CloudDownloadIcon style={{ color: 'white' }} />
                  )}
                </IconButton>
              </Tooltip>
              }
            {addAccess && (
              <AddButton onClick={handleAddClick}><AddIcon fontSize='small' sx={{ marginRight: '2px' }} />Add Project</AddButton>
            )}
          </div>
        </div>
        <TableContainer style={{ minHeight: '75px' }} >
          {getStatus === STATUS.PENDING && (
            <div className={classes.progressDiv}>
              <CircularProgress />
            </div>
          )}
          {getStatus === STATUS.SUCESS && (
            <Table className={classes.tableContainer} aria-label="simple table">
              <TableHeader headers={tableFields.map(capitalizeAll)} />
              <TableBody>
                {getStatus === STATUS.SUCESS && projects && projects.results && projects.results.length > 0 ? (
                  projects.results.map((row: any) => (
                    <TableRowItem
                      key={row.id}
                      row={row}
                      onDeleteClick={handleDeleteClick}
                      onToggleDisable={handleToggleDisable}
                      handleClick={handleClick}
                      statuses={statuses}
                      onStatusChange={handleStatusChange}
                    />
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} className={classes.noUsersMessage}>
                      <Error message={'There are no projects added as of yet. You can add a project to see it here.'} />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        <ProjectPagination
          currentPage={currentPage}
          totalPages={totalPages}
          paginate={paginate}
          itemsPerPage={itemsPerPage}
        />
        <DisableModal
          open={showDisableModal}
          onCancel={handleDisableCancel}
          onConfirm={handleDisableConfirm}
          isDisabled={projectToDisable ? projectToDisable.is_disabled : false}
        />
        <SnackbarNotification
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          onClose={handleSnackbarClose}
        />
      </>
      )}
    </>
  );
}

export default AllTable;

