import React from 'react';
import Modal from '@mui/material/Modal';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import { CircularProgress, Divider, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { STATUS } from '../../utils/status';
import { setUpdateUsersStatus } from '../../redux/slices/userSlice/addUserSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store/appStore';

interface DeleteModalProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  deleteText?: string;
  isButtonLoading?: boolean;
}

const DeleteModal: React.FC<DeleteModalProps> = ({ open, onCancel, onConfirm, deleteText = 'Delete', isButtonLoading = false }) => {
  return (
    <Modal
      open={open}
      onClose={onCancel}
      aria-labelledby="delete-modal-title"
      aria-describedby="delete-modal-description"
    >
      <>
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '16px', borderRadius: '8px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Typography variant="h6" gutterBottom style={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '16px', lineHeight: '18px', display: 'flex', alignItems: 'center', color: '#3D85C6' }}>
                {deleteText}? <CloseIcon onClick={onCancel} fontSize='small' style={{ color: '#3D85C6', cursor: 'pointer', marginLeft: '200px' }} />
             </Typography>
          </div>
        </div>
          <Divider style={{ marginBottom: '10px' }} />

          <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '500', fontSize: '14px', lineHeight: '18px', color: '#3D85C6', marginTop: '30px' }} id="delete-modal-description">Are you sure you want to {deleteText.toLowerCase()} it?</Typography>
          <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '500', fontSize: '14px', lineHeight: '18px', color: '#3D85C6' }} id="delete-modal-description">This will unallocate reviewers as well.</Typography>
          <br/>
          <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '500', fontSize: '14px', lineHeight: '18px', color: '#3D85C6' }} id="delete-modal-description">You can't undo this change later.</Typography>
          <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'flex-end' }}>

            <Button sx={{ minWidth: '5px', minHeight: '40px', backgroundColor: '#F7F9FA', padding: '8px, 16px, 8px, 16px', gap: '4px', borderRadius: '8px', marginRight: '10px' }} onClick={onCancel}>
              <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '14px', lineHeight: '24px', textAlign: 'center', color: '#10383D', textTransform: 'none' }}>Cancel</Typography>
            </Button>

            <Button variant='contained' color='error' sx={{ minWidth: '166px', minHeight: '40px', backgroundColor: deleteText ? '#3D85C6' : '#C00F00', padding: '8px, 16px, 8px, 16px', gap: '4px', borderRadius: '8px', '&:hover': { backgroundColor: deleteText ? '#3D85C6' : '#C00F00' } }} onClick={onConfirm}
              disabled={isButtonLoading === true}
              startIcon={isButtonLoading ? <CircularProgress size={17} /> : ''}
            >
              <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '14px', lineHeight: '24px', textAlign: 'center', color: '#FFFFFF', textTransform: 'none' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {deleteText !== 'Delete' ? '' : <DeleteIcon />} Confirm {deleteText}
                </div>
              </Typography>
            </Button>
          </div>
        </div>
      </>
    </Modal>
  );
}

export default DeleteModal;
