import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { useDispatch, useSelector } from 'react-redux';
import { getProject } from '../../redux/slices/projectSlice/addProjectSlice';
import { useParams } from 'react-router-dom';

type CardContent = {
  [key: string]: {
    description: string[];
  };
};

// const cardContent: CardContent = {
//   'Type 1': {
//     description: [
//       'Select tasks by status (COMPLETED, ACTIVE, NOT STARTED, or pending review) and reassign them to email IDs easily.',
//     ]
//   },
//   'Type 2': {
//     description: [
//       "Unallocation done by entering email IDs and the number of tasks, and it'll only affect tasks that have not been started.",
//     ]
//   },
//   'Type 3': {
//     description: [
//       'Allocation by uploading a sheet with task IDs, email IDs, and counts.',
//     ]
//   }
// };


interface ChooseTypeAllocationProps {
  selectedType: string | null;
  onTypeClick: (type: string) => void;
  activeStep: number; 
  onDoubleClick: (step: number) => void;
  allocationType: string;
}

const ChooseTypeAllocation: React.FC<ChooseTypeAllocationProps> = ({ selectedType, onTypeClick, activeStep, onDoubleClick, allocationType }) => {
  const [keyword, setKeyword] = useState('');
  const [cardContent, setCardContent] = useState<CardContent>({
    'Type 1': {
      description: [
        'This includes Task those are COMPLETED, ACTIVE, NOT STARTED, or Pending for REVIEW assign them to email IDs easily.',
      ],
    },
    'Type 2': {
      description: [
        "Unallocation done by entering email IDs and the number of tasks, and it'll only affect tasks that have not been started.",
      ],
    },
    'Type 3': {
      description: [
        'Allocation by uploading a sheet with task IDs, email IDs, and counts.',
      ],
    },
  });


  const getTypeBoxStyles = (type: string) => ({
    cursor: 'pointer',
    background: selectedType === type
      ? 'linear-gradient(135deg, #4A90E2, #50B6FF)' 
      : 'linear-gradient(135deg, #D9EAF9, #ECF5FF)', 
    borderColor: selectedType === type ? '#4A90E2' : '#E0E0E0',
    boxShadow: selectedType === type
      ? '0 8px 16px rgba(74, 144, 226, 0.4)'
      : '0 4px 8px rgba(0, 0, 0, 0.1)', 
    color: selectedType === type ? '#fff' : '#1E2C38',
    transition: 'all 0.3s ease',
    width: 300,
    height: 200, 
    borderRadius: 4, 
    padding: '24px',
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  });

  const getIconButtonStyles = (type: string) => ({
    backgroundColor: selectedType === type ? '#ffffff33' : '#F0F0F0',
    transition: 'background-color 0.3s ease',
  });

  const iconMap: Record<string, React.ReactNode> = {
    'Type 1': <LooksOneIcon />,
    'Type 2': <LooksTwoIcon />,
    'Type 3': <Looks3Icon />
  };

  const typeTitles: Record<string, string> = {
    'Type 1': 'Task Selection',
    'Type 2': 'Task Count',
    'Type 3': 'Task IDs'
  };

  const association_type = useSelector((state: RootState) => state.projects.association_type);
  const dispatch = useDispatch<AppDispatch>();
  const {id} = useParams();

  useEffect(()=>{
   dispatch(getProject({project_id:id}))
  },[id])

  const typesToShow = (allocationType === 'unallocation' ? ['Type 1', 'Type 2'] : ['Type 1', 'Type 2', 'Type 3'])
    .filter(type => !(association_type === 'VENDOR' && type === 'Type 3'));

  useEffect(() => {
    const extractKeywordFromURL = (): string => {
      const urlPath = window.location.pathname;
      const pathSegments = urlPath.split('/');
      const lastSegment = pathSegments[pathSegments.length - 1];
      return lastSegment ? decodeURIComponent(lastSegment) : '';
    };

    const currentKeyword = extractKeywordFromURL();
    setKeyword(currentKeyword);
  }, []);

  // Update cardContent based on the keyword
  useEffect(() => {
    if (keyword) {
      console.log('Extracted Keyword:', keyword);

      // Create a new object to update state immutably
      const updatedCardContent = { ...cardContent };

      switch (keyword) {
        case 'Task Unallocation':
          updatedCardContent['Type 2'].description = [
            'Unallocation of tasks that have not been started by entering email IDs and task numbers.',
          ];
          break;
        case 'Task Allocation':
          updatedCardContent['Type 3'].description = [
            'Allocate tasks by uploading a sheet with task IDs, email IDs, and counts.',
          ];
          break;
        case 'Review Allocation':
          updatedCardContent['Type 1'].description = [
            'Review allocations by selecting tasks in COMPLETED, ACTIVE, NOT STARTED, or pending review status and assigning to email IDs.',
          ];
          break;
        case 'Review Unallocation':
          updatedCardContent['Type 1'].description = [
            'Review unallocations by selecting and modifying tasks based on their status.',
          ];
          break;
        default:
          console.log('Unknown keyword:', keyword);
      }
      setCardContent(updatedCardContent);
    }
  }, [keyword]);
    

  return (
    <Box display="flex" justifyContent={allocationType === 'unallocation' || association_type === 'VENDOR' ? 'space-evenly': 'space-between'} mt={10} gap={2} mx={12}> 
      {typesToShow.map((type) => (
        <Box
          key={type}
          sx={getTypeBoxStyles(type)}
          onClick={() => onTypeClick(type)}
          onDoubleClick={() => onDoubleClick(activeStep + 1)}
        >
          <IconButton 
            style={getIconButtonStyles(type)} 
            size="large"
            sx={{
              color: selectedType === type ? '#ffffff' : '#4A90E2', 
              fontSize: '480px', 
              backgroundColor: selectedType === type ? '#ffffff33' : '#F0F0F0',
              transition: 'background-color 0.3s ease',
            }}
          >
            {iconMap[type]}
          </IconButton>
          <Typography variant="h6" mt={2} fontFamily="Poppins" fontWeight={700}>
            {typeTitles[type]}
          </Typography>
          {cardContent[type as keyof CardContent]?.description.map((point: string, index: number) => (
            <Typography
              key={index}
              variant="body2"
              color={selectedType === type ? '#fff' : '#1E2C38'}
              mb={1}
              sx={{ display: 'flex', alignItems: 'center', fontFamily: 'Montserrat', fontSize: '10px', fontWeight: 600, marginTop: '0px' }}
            >
              <Box
                component="span"
                sx={{
                  width: 0,
                  height: 8,
                  borderRadius: '50%',
                  backgroundColor: selectedType === type ? '#fff' : '#4A90E2',
                  mr: 1,
                }}
              />
              {point}
            </Typography>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default ChooseTypeAllocation;
