import { TableRow } from '@mui/material'
import React from 'react'
import { TableCellBody } from '../table/tableCell'

const VendorListTableRow = ({row}:any) => {
  return (
    <TableRow
    key={row.id}
  >
    <TableCellBody>{row.Pseudo_Name ? row.Pseudo_Name : '-'}</TableCellBody> 
    <TableCellBody>{row.Total_Tasker_Onboarded_On_Project ? row.Total_Tasker_Onboarded_On_Project : '-'}</TableCellBody>
    <TableCellBody>{row.Total_Task ? row.Total_Task : '-'}</TableCellBody>
    <TableCellBody>{row.Total_Task_Allocated ? row.Total_Task_Allocated : '-'}</TableCellBody>
    <TableCellBody>{row.Number_Of_Task_Completed ? row.Number_Of_Task_Completed : '-'}</TableCellBody>
    <TableCellBody>{row.Percent_Completed_Task ? row.Percent_Completed_Task : '-'}</TableCellBody>
    <TableCellBody>{row.Number_Of_Task_Not_Started ? row.Number_Of_Task_Not_Started : '-'}</TableCellBody>
    <TableCellBody>{row.Number_Of_Active_Task ? row.Number_Of_Active_Task : '-'}</TableCellBody>
    <TableCellBody>{row.Number_Of_Quality_Accepted_Task ? row.Number_Of_Quality_Accepted_Task : '-'}</TableCellBody>
    <TableCellBody>{row.Number_Of_Quality_Rejected_Task ? row.Number_Of_Quality_Rejected_Task : '-'}</TableCellBody>
    <TableCellBody>{row.Number_Of_Delivered_Task ? row.Number_Of_Delivered_Task : '-'}</TableCellBody>
    <TableCellBody>{row.Number_Of_Task_With_No_Review ? row.Number_Of_Task_With_No_Review : '-'}</TableCellBody>
    <TableCellBody>{row.Time_Taken_To_Completed ? row.Time_Taken_To_Completed : '-'}</TableCellBody>
    <TableCellBody>{row.Avg_Task_Completion_Per_Day ? row.Avg_Task_Completion_Per_Day : '-'}</TableCellBody>


  </TableRow>
  )
}

export default VendorListTableRow