import React, { useEffect, useMemo, useState } from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { colors } from '../../utils/constants';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import MuITooltip from '@mui/material/Tooltip';

interface ChartDataItem {
    date: any;
    [key: string]: string | number;
}

const Linechart: React.FC<{ data: any, dataStatus: string, title: string }> = ({ data, dataStatus, title }) => {
    const [loader, setLoader] = useState(false)
    const [taskMoreThenThousand, setTaskMoreThanThousand] = useState(false)
    const [activeLine, setActiveLine] = useState<string | null>(null);

    useEffect(() => {
        if (dataStatus === "PENDING") {
            setLoader(true)
        } else {
            setLoader(false)
        }
    })

    const processedData: ChartDataItem[] = useMemo(() => {
        if (data.length === 0) return [];
    
        const dateMap = new Map<string, ChartDataItem>();
    
        data.forEach((item: { date: any; pseudo_name: string; task_completed_count: string | number }) => {
            const key = item.date;
            if(Number(item.task_completed_count) > 999){
                setTaskMoreThanThousand(true)
            }
            if (!dateMap.has(key)) {
                dateMap.set(key, { date: item.date });
            }
            const entry = dateMap.get(key)!;
            const name = item.pseudo_name || "No Name";
            entry[name] = Number(entry[name] || 0) + Number(item.task_completed_count);
        });

        return Array.from(dateMap.values()).sort(
            (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
        );
    }, [data]);

    const pseudoNames: string[] = useMemo(() => {
        const names = new Set<string>();
        data.forEach((item: { pseudo_name: any; }) => names.add(item.pseudo_name || 'No Name'));
        return Array.from(names);
    }, [data]);

    if (loader) {
        return (
            <div className="flex items-center justify-center h-[400px] bg-gray-100 rounded-lg">
                <p className="text-gray-500 text-lg">Loading ....</p>
            </div>
        );
    } else if (data.length === 0) {
        return (
            <div className="flex items-center justify-center h-[400px] bg-gray-100 rounded-lg">
                <p className="text-gray-500 text-lg">No Data Available</p>
            </div>
        )
    }
    // console.log({data})


    const handleLegendClick = (name: string) => {
        setActiveLine((prevActiveLine) => (prevActiveLine === name ? null : name));
    };

    
    const CustomLegend = (props: any) => {
        const { payload } = props;
        
        
        return (
            <div className="recharts-default-legend" style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "10px" }}>
                {payload.map((entry: any) => (
                    <MuITooltip title="Click to toggle the selection of names.">
                        <span
                            key={entry.value}
                            onClick={() => handleLegendClick(entry.value)}
                            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                        >
                            <FiberManualRecordIcon style={{ color: entry.color, marginRight: 5, marginLeft: "10px" }} />
                            {entry.value}
                        </span>
                    </MuITooltip>
                ))}
            </div>
        );
    };


    return (

        <div style={{ display: 'flex', alignItems: 'center', position: 'relative', paddingTop: "10px" }}>
            <h2 style={{ writingMode: 'vertical-rl', transform: 'rotate(180deg)', margin: 0, paddingRight: 5, wordSpacing: "10px", letterSpacing: "5px"}}>
                {title}
            </h2>
            <ResponsiveContainer width="100%" height={550}>
                <LineChart data={processedData} margin={{ top: 5, right: 10, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    {/* <Legend onClick={(e) => handleLegendClick(e.value)} /> */}
                    <Legend content={<CustomLegend />} />
                    {pseudoNames.map((name, index) => {
                        return (
                            <Line
                                key={name}
                                type="monotone"
                                dataKey={name}
                                stroke={activeLine === null || activeLine === name ? colors[index % colors.length] : '#d3d3d3'}
                                activeDot={{ r: 10 }}
                                strokeWidth={2}
                            />
                        )
                    })}
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}

export default Linechart