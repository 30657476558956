import { ActionReducerMapBuilder, current, PayloadAction } from '@reduxjs/toolkit';
import { createProject, fetchProjectTypes, fetchTaskCategories, getProject, getProjectExperts, updateProject} from '../slices/projectSlice/addProjectSlice';
import { STATUS } from '../../utils/status';
import { ProjectTypeState } from '../interfaces/interface';
import { useId } from 'react';

const ExtraReducers = (builder: ActionReducerMapBuilder<ProjectTypeState>) => {
  builder
    .addCase(createProject.pending, (state) => {
      state.createStatus = STATUS.PENDING;
    })
    .addCase(createProject.fulfilled, (state, action: PayloadAction<any>) => {
      state.projects = action.payload; 
      state.createStatus = STATUS.SUCESS; 
    })
    .addCase(createProject.rejected, (state, action) => { 
      state.createStatus = STATUS.FAILED;
    })
    .addCase(getProject.pending, (state) => {
      state.getStatus = STATUS.PENDING;
    })
    .addCase(getProject.fulfilled, (state, action: PayloadAction<any>) => {
      if (Object.keys(action.payload).includes('project')) {
        state.project = action.payload.project;
        state.association_type = action.payload.association_type;
        state.permissions = action.payload.permissions;
      } else {
        state.projects = action.payload
      }
      state.getStatus = STATUS.SUCESS; 
    })
    .addCase(getProject.rejected, (state, action) => {
      state.getStatus = STATUS.FAILED;
    })    
    .addCase(updateProject.pending, (state) => {
      state.updateStatus = STATUS.PENDING;
    })
    .addCase(updateProject.fulfilled, (state, action: PayloadAction<any>) => {
      state.projects = action.payload.data;
      if (Object.keys(action.payload.payload).includes('assign') && action.payload.payload.assign.taskers) {
        state.taskExperts.push({
          expert_id: action.payload.payload.assign.taskers[0],
          expert_email: action.payload.payload.email,
        });
      }
      if (Object.keys(action.payload.payload).includes('assign') && action.payload.payload.assign.reviewers) {
        state.reviewExperts.push({
          expert_id: action.payload.payload.assign.reviewers[0],
          expert_email: action.payload.payload.email,
        });
      }
      state.updateStatus = STATUS.SUCESS;   
    })   
    .addCase(updateProject.rejected, (state, action) => { 
      state.updateStatus = STATUS.FAILED;
    })
    .addCase(fetchProjectTypes.pending, (state) => {
      state.getProjectTypesStatus = STATUS.PENDING
    })
    .addCase(fetchProjectTypes.fulfilled, (state, action: PayloadAction<any>) => {
      state.getProjectTypesStatus = STATUS.SUCESS
      state.projectTypeOptions = action.payload;
    })
    .addCase(fetchProjectTypes.rejected, (state) => {
      state.getProjectTypesStatus = STATUS.FAILED
    })
    .addCase(fetchTaskCategories.pending, (state) => {
      state.getTaskCategoryStatus = STATUS.PENDING
    })
    .addCase(fetchTaskCategories.fulfilled, (state, action: PayloadAction<any>) => {
      state.getTaskCategoryStatus = STATUS.SUCESS
      state.taskCategoryOptions = action.payload
    })
    .addCase(fetchTaskCategories.rejected, (state) => {
      state.getTaskCategoryStatus = STATUS.FAILED
    })
    .addCase(getProjectExperts.pending, (state, action: PayloadAction<any>) => {
      state.getProjectExpertsStatus = STATUS.PENDING
    })
    .addCase(getProjectExperts.fulfilled, (state, action: PayloadAction<any>) => {
      state.getProjectExpertsStatus = STATUS.SUCESS
      state.projectExperts = action.payload
      // console.log({pe: action.payload})
      if (action.payload.type === 'TASK') {
        state.taskExperts = action.payload.data; 
        state.projectExperts = action.payload.data;
      } else if (action.payload.type === 'REVIEW') {
        state.reviewExperts = action.payload.data; 
        state.projectExperts = action.payload.data;
      }
    })
    .addCase(getProjectExperts.rejected, (state, action: PayloadAction<any>) => {
      state.getProjectExpertsStatus = STATUS.FAILED
    })
};

export default ExtraReducers;
